import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/authUtils";
import { pageUrls } from "../common/constants";
import { showErrors } from "../common/utils";
import ComponentInfo from "../components/ComponentInfo";
import Placeholder from "../components/Placeholder";
import MainPageSettingsModel from "../models/MainPageSettingsModel";
import SettingsService from "../services/SettingsService";
import SaveNewWordBlock from "./mainPage/SaveNewWordBlock";

const MainPage: React.FC = (props) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false); // когда внутренний запрос идёт
    const [isFetching, setIsFetching] = useState<boolean>(true); // когда инициализация страницы происходит
    const [mainPageSettings, setMainPageSettings] = useState<MainPageSettingsModel | null>(null);
    const { isAuthenticated } = useAuth();
    let navigate = useNavigate();
    let settingsService = new SettingsService();

    const getMainPageSettings = async () => {
        let settingsResult = await settingsService.getMainPageSettings();

        if (!settingsResult.hasErrors) {
            setMainPageSettings(settingsResult.value);
        } else {
            showErrors(...settingsResult.errors);
        }
    };

    let initRequest = false;

    useEffect(() => {
        if (!initRequest) {
            initRequest = true;
            if (isAuthenticated) {
                setIsFetching(true);
                getMainPageSettings().then(() => {
                    setIsFetching(false);
                });
            } else {
                navigate(pageUrls.URL_LOGIN);
            }
        }
    }, []);

    return (
        <>
            {isUpdating ? <Placeholder isUpdating={isUpdating} /> : null}
            {isFetching ? (
                <Placeholder />
            ) : (
                <MainPageContent
                    mainPageSettings={mainPageSettings}
                    isUpdating={isUpdating}
                    setIsUpdating={setIsUpdating}
                    getMainPageSettings={getMainPageSettings}
                />
            )}
        </>
    );
};

interface MainPageContentProps {
    mainPageSettings: MainPageSettingsModel | null;
    isUpdating: boolean;
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
    getMainPageSettings: () => void;
}

const MainPageContent: React.FC<MainPageContentProps> = (props) => {
    return (
        <>
            <SaveNewWordBlock
                isUpdating={props.isUpdating}
                setIsUpdating={props.setIsUpdating}
                // allTags={["tag_1_from_all_tags", "tag_2_from_all_tags", "tag_3_from_all_tags", "tag_4_from_all_tags"]}
                mainPageSettings={props.mainPageSettings}
                getMainPageSettings={props.getMainPageSettings}
            />
        </>
    );
};

export default MainPage;
