import "../src/styles/styles.scss";
import React from "react";
import Layout from "./components/Layout";
import Routes from "./routes";

const App = () => {
    return (
        <Layout>
            <Routes />
        </Layout>
    );
};

export default App;
