import * as React from "react";
import { useEffect, useState } from "react";
import { showSuccess } from "../common/utils";
import { login as saveLogin_to_local_storage } from "../common/authUtils";
import { useNavigate } from "react-router-dom";

import ComponentInfo from "../components/ComponentInfo";
import Placeholder from "../components/Placeholder";
import { pageUrls } from "../common/constants";
import { useAuth } from "../common/authUtils";
import AuthService from "../services/AuthService";
import { useForm, SubmitHandler, FieldErrorsImpl } from "react-hook-form";
import { Link, TextField, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
type FormInputs = {
    login: string;
    password: string;
    email: string;
};

const RegisterPage: React.FC = (props) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false); // когда внутренний запрос идёт

    const { isAuthenticated } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(pageUrls.URL_BASE);
        }
    }, []);

    return (
        <>
            {isUpdating ? <Placeholder isUpdating={isUpdating} /> : null}
            <RegisterPageContent {...props} setIsUpdating={setIsUpdating} />
        </>
    );
};

interface RegisterPageContentProps {
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterPageContent: React.FC<RegisterPageContentProps> = (props) => {
    const [isSubmitting, setSubmitting] = useState<boolean>(false); // когда внутренний запрос идёт
    let navigate = useNavigate();
    const [clickedSubmitButton, setClickedSubmitButton] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormInputs>();
    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        setSubmitting(true);
        const service = new AuthService();
        let result = await service.register(data.login, data.email, data.password);
        if (!result.hasErrors) {
            showSuccess("Logged in successfully");
            saveLogin_to_local_storage(result.value.access_token, result.value.login);

            navigate(pageUrls.URL_BASE);
        }
        setSubmitting(false);
    };

    // console.log(watch("login")) // watch input value by passing the name of it

    return (
        <div className="form">
            <div className="logo">Register</div>
            <form onSubmit={handleSubmit(onSubmit)} className="form_items">
                <TextField
                    {...register("login", {
                        required: {
                            value: true,
                            message: "Login is required",
                        },
                        minLength: {
                            value: 4,
                            message: "Login must be at least 4 characters",
                        },
                        maxLength: {
                            value: 255,
                            message: "Login must be less than 255 characters",
                        },
                    })}
                    label="Login"
                    type="text"
                    className="form_inputs"
                    error={errors.login ? true : false}
                    helperText={errors.login?.message}
                />

                <TextField
                    {...register("email", {
                        required: {
                            value: true,
                            message: "Email is required",
                        },
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Email has incorrect format",
                        },
                        maxLength: {
                            value: 255,
                            message: "Email must be less than 255 characters",
                        },
                    })}
                    label="Email"
                    type="text"
                    className="form_inputs"
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                />

                <TextField
                    {...register("password", {
                        required: {
                            value: true,
                            message: "Password is required",
                        },
                        minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                        },
                        maxLength: {
                            value: 255,
                            message: "Password must be less than 255 characters",
                        },
                    })}
                    label="Password"
                    type="password"
                    className="form_inputs"
                    error={errors.password ? true : false}
                    helperText={errors.password?.message}
                />

                <Button
                    disabled={isSubmitting}
                    className="form_button"
                    color="success"
                    variant="contained"
                    type="submit"
                >
                    Register
                </Button>
                <div className="form_other_register">
                    <Link href="/login">
                        <KeyboardBackspaceIcon></KeyboardBackspaceIcon>Back to login
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default RegisterPage;
