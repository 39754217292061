import { localStorageConstants } from "./constants";
import WordsPageSettingsModel from "../models/WordsPageSettingsModel";

// if we have a data from local storage, we use it
// otherwise we use default values
export const useWordsPageSettings = (): WordsPageSettingsModel => {
    const localStorageData = localStorage.getItem(localStorageConstants.WORDS_PAGE_DATA_STORAGE_NAME);
    if (localStorageData) {
        const localWordsPageDataSettings: WordsPageSettingsModel = JSON.parse(localStorageData);
        return {
            tableFilters: localWordsPageDataSettings.tableFilters,
            tableParams: localWordsPageDataSettings.tableParams,
        } as WordsPageSettingsModel;
    } else {
        return {
            tableFilters: {
                langIso: "",
                tags: null,
                search: null,
                isStrictTagsSearch: true,
            },
            tableParams: {
                total: 0,
                page: 1,
                pageSize: 20,
                orderBy: "value",
                sortDirection: "asc",
            },
        } as WordsPageSettingsModel;
    }
};

export const insertOrUpdateWordsPageSettings = (data: WordsPageSettingsModel) => {
    localStorage.setItem(
        localStorageConstants.WORDS_PAGE_DATA_STORAGE_NAME,
        JSON.stringify({
            ...data,
        } as WordsPageSettingsModel)
    );

    localStorage.setItem("from_auth", "true");
};

export const removeWordsPageSettingsFromCache = () => {
    localStorage.removeItem(localStorageConstants.WORDS_PAGE_DATA_STORAGE_NAME);
};
