import React, { useEffect, useState } from "react";
import { WordsTableFilters } from "../../models/WordsPageSettingsModel";
import { CustomSelect, OptionType } from "../../components/CustomSelect";
import { languagesIsoCodes } from "../../common/constants";
import { isDef } from "../../common/utils";
import ComponentInfo from "../../components/ComponentInfo";

import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";

interface WordsPageFilterPanelProps {
    allTags: string[];
    allLangs: string[];
    initialFilters: WordsTableFilters;
    onFilterChange: (filters: WordsTableFilters) => void;
}

const WordsPageFilterPanel: React.FC<WordsPageFilterPanelProps> = ({
    allTags,
    allLangs,
    initialFilters,
    onFilterChange,
}) => {
    const [filters, setFilters] = useState<WordsTableFilters>({ ...initialFilters });

    useEffect(() => {
        if (filters.langIso !== initialFilters.langIso) {
            handleApplyFilters();
        }
    }, [filters.langIso]);

    const handleTagChange = (event: any, selectedTagsOptions: Array<OptionType> | any) => {
        setFilters((prevFilters: WordsTableFilters) => ({
            ...prevFilters,
            tags: selectedTagsOptions.map((el: OptionType) => el.value),
        }));
    };

    const handleSearchChange = (searchText: string) => {
        setFilters((prevFilters: WordsTableFilters) => ({
            ...prevFilters,
            search: searchText,
        }));
    };

    const handleLanguageChange = (event: SelectChangeEvent) => {
        setFilters((prevFilters: WordsTableFilters) => ({
            ...prevFilters,
            langIso: event.target.value,
            tags: [],
        }));
    };

    const handleStrictTagsSearchChange = (event: React.SyntheticEvent, checked: boolean) => {
        setFilters((prevFilters: WordsTableFilters) => ({
            ...prevFilters,
            isStrictTagsSearch: checked,
        }));
    };

    const handleApplyFilters = () => {
        onFilterChange(filters);
    };

    let allLangsOptions: Array<OptionType> = [];
    for (const [key, value] of Object.entries(languagesIsoCodes)) {
        if (!allLangs.includes(key)) {
            continue;
        }
        allLangsOptions.push({ value: key, label: value });
    }
    allLangsOptions = allLangsOptions.sort((x, y) => {
        return x.label < y.label ? 1 : -1;
    });

    let selectedTags = isDef(filters?.tags)
        ? // @ts-ignore
          filters.tags.map((el) => {
              return {
                  value: el,
                  label: el,
              } as OptionType;
          })
        : [];

    const allTagsOptions = allTags
        .map((tag) => {
            return {
                value: tag,
                label: tag,
            } as OptionType;
        })
        .sort((x, y) => {
            return x.label < y.label ? 1 : -1;
        });

    // remove from allTagsOptions tags that are already selected
    selectedTags.forEach((selectedTag) => {
        const index = allTagsOptions.findIndex((el) => el.value === selectedTag.value);
        if (index !== -1) {
            allTagsOptions.splice(index, 1);
        }
    });

    return (
        <div className="words-page-filter">
            <FormGroup row>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Language</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filters.langIso}
                        label="Language"
                        onChange={handleLanguageChange}
                    >
                        {allLangsOptions.map((el) => {
                            return (
                                <MenuItem key={el.value} value={el.value}>
                                    {el.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {/* <div className="language-filter">
                    <label>Language:</label>
                    <CustomSelect
                        fieldName={"langCode"}
                        setValue={handleLanguageChange}
                        placeholder="Select a language"
                        options={allLangsOptions}
                        isCreatable={false}
                        isMulti={false}
                        selectedValues={[
                            {
                                value: filters.langIso,
                                // @ts-ignore
                                label: languagesIsoCodes[filters.langIso ?? "en"],
                            },
                        ]}
                    />
                </div> */}
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <TextField
                        size="small"
                        label="Search"
                        variant="outlined"
                        value={filters.search || ""}
                        onChange={(e) => handleSearchChange(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleApplyFilters();
                            }
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button variant="contained" onClick={handleApplyFilters}>
                        Apply Filters
                    </Button>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Autocomplete
                        size="small"
                        multiple
                        id="tags-outlined"
                        className="tags-filter"
                        options={allTagsOptions}
                        getOptionLabel={(option) => option.label}
                        value={[...selectedTags]}
                        filterSelectedOptions={true}
                        renderInput={(params) => <TextField {...params} label="Filter by tags" placeholder="Tags" />}
                        onChange={handleTagChange}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 120, "margin-top": 0 }} size="small">
                    <FormControlLabel
                        control={<Checkbox defaultChecked value={filters.isStrictTagsSearch} />}
                        label="Strict tags"
                        onChange={handleStrictTagsSearchChange}
                    />
                </FormControl>
            </FormGroup>
            {/* <ComponentInfo props={filters} /> */}
        </div>
    );
};

export default WordsPageFilterPanel;
