import React from "react";
import { isDef } from "../common/utils";
import "../styles/preloader.scss";

type Props = {
    isUpdating?: boolean;
};

const Placeholder = (props: Props) => (
    <div
        className={`preloaderBody ${
            isDef(props.isUpdating) && props.isUpdating ? "updating" : ""
        }`}
    >
        <div className="content">
            <div className="loading">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);

export default Placeholder;
