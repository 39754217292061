import React, { useState } from "react";
import { WordsTableFilters, WordsTableParams } from "../../models/WordsPageSettingsModel";
import { CustomSelect, OptionType } from "../../components/CustomSelect";
import { languagesIsoCodes } from "../../common/constants";
import { isDef } from "../../common/utils";
import ComponentInfo from "../../components/ComponentInfo";
import WordModel from "../../models/WordModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment-timezone";
import Highlighter from "react-highlight-words";
import { Box, TableFooter, TablePagination } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

interface WordsPageTableProps {
    searchStr: string | null;
    words: Array<WordModel>;
    tableParams: WordsTableParams;
    setTableParams: (params: WordsTableParams) => void;
}

interface Data {
    calories: number;
    carbs: number;
    fat: number;
    name: string;
    protein: number;
}

// Получение локального часового пояса компьютера
const localTimeZone = moment.tz.guess();

const convertFromStringTimeUtcToLocal = (utcTime: string) => {
    return moment.utc(utcTime).tz(localTimeZone).format("YYYY-MM-DD HH:mm");
};

const WordsTable: React.FC<WordsPageTableProps> = ({ words, tableParams, setTableParams, searchStr }) => {
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTableParams({ ...tableParams, page: 1, pageSize: parseInt(event.target.value, 10) });
    };

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        const isAsc = tableParams.orderBy === property && tableParams.sortDirection === "asc";
        setTableParams({
            ...tableParams,
            orderBy: property,
            sortDirection: isAsc ? "desc" : "asc",
        });
    };

    return (
        <div>
            {/* <ComponentInfo
                // join all words to string separated by comma
                tableParams={tableParams}
            /> */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 100]}
                                count={tableParams.total}
                                rowsPerPage={tableParams.pageSize}
                                page={tableParams.page - 1}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                    value: tableParams.pageSize,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                // ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                        <TableRow>
                            <TableCell
                                key={"value"}
                                sortDirection={
                                    tableParams.orderBy === "value"
                                        ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                        : false
                                }
                            >
                                <TableSortLabel
                                    active={tableParams.orderBy === "value"}
                                    direction={
                                        tableParams.orderBy === "value"
                                            ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                            : "asc"
                                    }
                                    onClick={createSortHandler("value")}
                                >
                                    {<b>Word</b>}
                                    {tableParams.orderBy === "value" ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {tableParams.sortDirection === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                key={"translation"}
                                sortDirection={
                                    tableParams.orderBy === "translation"
                                        ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                        : false
                                }
                            >
                                <TableSortLabel
                                    active={tableParams.orderBy === "translation"}
                                    direction={
                                        tableParams.orderBy === "translation"
                                            ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                            : "asc"
                                    }
                                    onClick={createSortHandler("translation")}
                                >
                                    {<b>Translation</b>}
                                    {tableParams.orderBy === "translation" ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {tableParams.sortDirection === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <b>Tags</b>
                            </TableCell>
                            <TableCell
                                key={"created_at"}
                                align="right"
                                sortDirection={
                                    tableParams.orderBy === "created_at"
                                        ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                        : false
                                }
                            >
                                <TableSortLabel
                                    active={tableParams.orderBy === "created_at"}
                                    direction={
                                        tableParams.orderBy === "created_at"
                                            ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                            : "asc"
                                    }
                                    onClick={createSortHandler("created_at")}
                                >
                                    {<b>Created Date</b>}
                                    {tableParams.orderBy === "created_at" ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {tableParams.sortDirection === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                key={"updated_at"}
                                align="right"
                                sortDirection={
                                    tableParams.orderBy === "updated_at"
                                        ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                        : false
                                }
                            >
                                <TableSortLabel
                                    active={tableParams.orderBy === "updated_at"}
                                    direction={
                                        tableParams.orderBy === "updated_at"
                                            ? (tableParams.sortDirection as "asc" | "desc" | undefined)
                                            : "asc"
                                    }
                                    onClick={createSortHandler("updated_at")}
                                >
                                    {<b>Updated Date</b>}
                                    {tableParams.orderBy === "updated_at" ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {tableParams.sortDirection === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {words.map((word) => (
                            <TableRow key={word._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    <Highlighter
                                        highlightClassName="highlight"
                                        searchWords={
                                            searchStr
                                                ? [
                                                      searchStr
                                                          .split(" ")
                                                          .filter((w) => w.length > 0)
                                                          .map((w) => w.trim())
                                                          .join(" "),
                                                  ]
                                                : []
                                        }
                                        autoEscape={true}
                                        textToHighlight={word.value}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Highlighter
                                        highlightClassName="highlight"
                                        searchWords={
                                            searchStr
                                                ? [
                                                      searchStr
                                                          .split(" ")
                                                          .filter((w) => w.length > 0)
                                                          .map((w) => w.trim())
                                                          .join(" "),
                                                  ]
                                                : []
                                        }
                                        autoEscape={true}
                                        textToHighlight={word.translation}
                                    />
                                </TableCell>
                                <TableCell align="right" sx={{ minWidth: "145px" }}>
                                    {word.tags.join(", ")}
                                </TableCell>
                                <TableCell align="right" sx={{ minWidth: "145px" }}>
                                    {convertFromStringTimeUtcToLocal(word.created_at_utc_str)}
                                </TableCell>
                                <TableCell align="right" sx={{ minWidth: "145px" }}>
                                    {convertFromStringTimeUtcToLocal(word.updated_at_utc_str)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 100]}
                                count={tableParams.total}
                                rowsPerPage={tableParams.pageSize}
                                page={tableParams.page - 1}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                    value: tableParams.pageSize,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                // ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default WordsTable;
