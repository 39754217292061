import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/authUtils";
import { pageUrls } from "../common/constants";
import Placeholder from "../components/Placeholder";

const ConfigPage: React.FC = (props) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false); // когда внутренний запрос идёт
    const [isFetching, setIsFetching] = useState<boolean>(true); // когда инициализация страницы происходит
    const { isAuthenticated } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            setIsFetching(true);
            console.log("[Config page] init request");
            setIsFetching(false);
        } else {
            navigate(pageUrls.URL_LOGIN);
        }
    }, []);

    return (
        <>
            {isUpdating ? <Placeholder isUpdating={isUpdating} /> : null}
            {isFetching ? (
                <Placeholder />
            ) : (
                <ConfigPageContent {...props} setIsUpdating={setIsUpdating} />
            )}
        </>
    );
};

interface ConfigPageContentProps {
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfigPageContent: React.FC<ConfigPageContentProps> = (props) => {
    return <>ConfigPageContent</>;
};

export default ConfigPage;
