import React, { ReactComponentElement } from "react";
import ReactDOM from "react-dom";
import { Routes, Route } from "react-router-dom";
import { pageUrls } from "./common/constants";
import MainPage from "./pages/MainPage";
import ConfigPage from "./pages/ConfigPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ForbiddenPage from "./pages/ForbiddenPage";
import NotFoundPage from "./pages/NotFoundPage";
import WordsPage from "./pages/WordsPage";

const AppRoutes: React.FC<{}> = () => {
    return (
        <Routes>
            <Route path={pageUrls.URL_BASE} element={<MainPage />} />
            <Route path={pageUrls.URL_LOGIN} element={<LoginPage />} />
            <Route path={pageUrls.URL_REGISTER} element={<RegisterPage />} />
            <Route path={pageUrls.URL_CONFIG} element={<ConfigPage />} />
            <Route path={pageUrls.URL_WORDS} element={<WordsPage />} />
            <Route path={pageUrls.URL_FORBIDDEN} element={<ForbiddenPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
