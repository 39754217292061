import {ServiceBase} from "../core/service-base";
import Result from "../core/result";
import {LocalStorageUserDataModel} from "../models/LocalStorageUserDataModel";
import {showErrors} from "../common/utils";

export default class AuthService extends ServiceBase {
    public async login(login:string, password: string): Promise<Result<LoginDataModel>> {
        const result = await this.requestJson<LoginDataModel>({
            url: `api/auth/login`,
            method: "POST",
            data: {
                "login": login,
                "password": password
            }
        });
        return result;
    }
    public async register(login:string, email:string, password: string): Promise<Result<LoginDataModel>> {
        const result = await this.requestJson<LoginDataModel>({
            url: `api/auth/register`,
            method: "POST",
            data: {
                "login": login,
                "email": email,
                "password": password
            }
        });
        return result;
    }
}

export interface LoginDataModel {
    access_token: string,
    login: string
}