import Result from "./result";
import Axios from "axios";
// @ts-ignore
import queryString from "query-string";
import { showErrors } from "../common/utils";
import { useAuth } from "../common/authUtils";
import { LocalStorageUserDataModel } from "../models/LocalStorageUserDataModel";
import { AuthCommonModel } from "../models/AuthCommonModel";
import { localStorageConstants } from "../common/constants";
// import i18n from "i18next";

export interface IRequestOptions {
    url: string;
    data?: any;
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
}

export interface ISendFormDataOptions {
    url: string;
    data: FormData;
    method: "POST" | "PUT" | "PATCH";
}

//const url = "http://127.0.0.1:8010";
//const url = "http://127.0.0.1:8000";
const url = process.env.NODE_ENV === "production" ? "https://my-words-api.vil.adlorf.art" : "http://127.0.0.1:3100";

const arr = url.split("/");
const baseUrl = arr[0] + "//" + arr[2];
const axiosInstance = Axios.create({
    baseURL: baseUrl,
});

let auth: AuthCommonModel = {
    accessToken: null,
    isAuthenticated: false,
    userLogin: null,
};

/**
 * Represents base class of the isomorphic service.
 */
export abstract class ServiceBase {
    /**
     * Make request with JSON data.
     * @param opts
     */
    public async requestJson<T>(opts: IRequestOptions): Promise<Result<T>> {
        let axiosResult = null;
        let result = null;

        //opts.url = transformUrl(opts.url); // Allow requests also for the Node.

        let headers: any = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Content-Type": "application/json",
        };

        const localSotrageData = localStorage.getItem(localStorageConstants.USER_DATA_STORAGE_NAME);
        if (localSotrageData) {
            const localUserData: LocalStorageUserDataModel = JSON.parse(localSotrageData);
            auth = {
                accessToken: localUserData.accessToken,
                isAuthenticated: !!localUserData.accessToken,
                userLogin: localUserData.userLogin,
            } as AuthCommonModel;
        }

        if (auth && auth.isAuthenticated && auth.accessToken) {
            headers["Authorization"] = `Bearer ${auth.accessToken}`;
        }

        const axiosOpts = {
            headers: headers,
        };

        const processQuery = (url: string, data: any): string => {
            // if some data property is null, remove it from data
            for (const key in data) {
                if (data[key] === null) {
                    delete data[key];
                }
            }

            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };
        try {
            let errors: Array<string> = new Array<string>();
            switch (opts.method) {
                case "GET":
                    axiosResult = await axiosInstance
                        .get(processQuery(opts.url, opts.data), axiosOpts)
                        .catch(function (error) {
                            if (error.response) {
                                error.response.data ? errors.push(error.response.data) : errors.push(error.message);
                            }
                        });
                    break;
                case "POST":
                    axiosResult = await axiosInstance.post(opts.url, opts.data, axiosOpts).catch(function (error) {
                        if (error.response) {
                            error.response.data ? errors.push(error.response.data) : errors.push(error.message);
                        }
                    });
                    break;
                case "PUT":
                    axiosResult = await axiosInstance.put(opts.url, opts.data, axiosOpts).catch(function (error) {
                        if (error.response) {
                            error.response.data ? errors.push(error.response.data) : errors.push(error.message);
                        }
                    });
                    break;
                case "PATCH":
                    axiosResult = await axiosInstance.patch(opts.url, opts.data, axiosOpts).catch(function (error) {
                        if (error.response) {
                            error.response.data ? errors.push(error.response.data) : errors.push(error.message);
                        }
                    });
                    break;
                case "DELETE":
                    axiosResult = await axiosInstance
                        .delete(processQuery(opts.url, opts.data), axiosOpts)
                        .catch(function (error) {
                            if (error.response) {
                                error.response.data ? errors.push(error.response.data) : errors.push(error.message);
                            }
                        });
                    break;
            }

            result = new Result(axiosResult?.data, ...errors);
        } catch (error: any) {
            result = new Result(null, error.message);
        }

        if (result.hasErrors) {
            showErrors(...result.errors);
        }

        return result;
    }
}
