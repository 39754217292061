import { ServiceBase } from "../core/service-base";
import Result from "../core/result";
import MainPageSettingsModel from "../models/MainPageSettingsModel";
import WordsPageSettingsModel from "../models/WordsPageSettingsModel";

export default class SettingsService extends ServiceBase {
    public async getMainPageSettings(): Promise<Result<MainPageSettingsModel>> {
        const result = await this.requestJson<MainPageSettingsModel>({
            url: `api/settings/main-page-settings`,
            method: "GET",
        });
        return result;
    }

    public async updateMainPageSettings(lang_iso: string, tags: Array<string>): Promise<Result<MainPageSettingsModel>> {
        const result = await this.requestJson<MainPageSettingsModel>({
            url: `api/settings/main-page-settings`,
            method: "PATCH",
            data: {
                lang_iso: lang_iso,
                tags: tags,
            },
        });
        return result;
    }

    // public async getAllFoldersYaDisk(): Promise<Result<Array<string>>> {
    //     const result = await this.requestJson<Array<string>>({
    //         url: `api/zakup/all-folders-ya-disk`,
    //         method: "GET"
    //     });
    //     return result;
    // }

    // public async createZakup(renobeList: Array<RenameRanobeModel>): Promise<Result<ZakupHistoryModel>> {
    //     const result = await this.requestJson<ZakupHistoryModel>({
    //         url: `api/zakup/create`,
    //         method: "POST",
    //         data: renobeList
    //     });
    //     return result;
    // }

    // public async updateSettingsForCurrentLoggedUser(settings: ZakupHistoryModel): Promise<Result<SettingsModel>> {
    //     const result = await this.requestJson<SettingsModel>({
    //         url: `api/settings/edit`,
    //         method: "POST",
    //         data: {
    //             ...settings,
    //         },
    //     });
    //     return result;
    // }
}
