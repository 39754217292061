import * as React from "react";
import { Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import NavMenu from "./NavMenu";
import NavMenu_1 from "./NavMenu_1";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { pageUrls } from "../common/constants";

export default (props: { children?: React.ReactNode }) => {
    const location = useLocation();
    const isShowNavMenu = location.pathname !== pageUrls.URL_LOGIN && location.pathname !== pageUrls.URL_REGISTER;

    const darkTheme = createTheme({
        palette: {
            mode: 'light', //dark
        },
    });
    return (
        <React.Fragment>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                {isShowNavMenu && <NavMenu_1 />}
                <Container>{props.children}</Container>
                <ToastContainer autoClose={1000} />
            </ThemeProvider>
        </React.Fragment>
    );
};
