import * as React from "react";
import { useEffect, useState } from "react";
import { showSuccess } from "../common/utils";
import { login as saveLogin_to_local_storage } from "../common/authUtils";
import { useNavigate } from "react-router-dom"

import ComponentInfo from "../components/ComponentInfo";
import Placeholder from "../components/Placeholder";
import { pageUrls } from "../common/constants";
import { useAuth } from "../common/authUtils";
import AuthService from "../services/AuthService";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, TextField, Button } from '@mui/material';

type FormInputs = {
    login: string,
    password: string,
};

const LoginPage: React.FC = (props) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false); // когда внутренний запрос идёт

    const { isAuthenticated } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(pageUrls.URL_BASE);
        }
    }, []);

    return (
        <>
            {isUpdating ? <Placeholder isUpdating={isUpdating} /> : null}
            <LoginPageContent {...props} setIsUpdating={setIsUpdating} />
        </>
    );
};

interface LoginPageContentProps {
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginPageContent: React.FC<LoginPageContentProps> = (props) => {
    const [isSubmitting, setSubmitting] = useState<boolean>(false); // когда внутренний запрос идёт
    let navigate = useNavigate();
    const [clickedSubmitButton, setClickedSubmitButton] = useState<boolean>(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormInputs>();
    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        setSubmitting(true);
        const service = new AuthService();
        let result = await service.login(data.login, data.password);
        if (!result.hasErrors) {
            showSuccess("Logged in successfully");
            saveLogin_to_local_storage(
                result.value.access_token,
                result.value.login,
            );

            navigate(pageUrls.URL_BASE);
        }
        setSubmitting(false);
    };

    // console.log(watch("login")) // watch input value by passing the name of it

    return (
        <div className='form'>
            <div className="logo">Log In</div>
            <form onSubmit={handleSubmit(onSubmit)} className='form_items'>
                <TextField {...register("login", { required: true })} label="Login" type="text" className='form_inputs'
                    error={errors.login ? true : false}
                    helperText={errors.login ? "Login is required" : ""} />

                <TextField {...register("password", { required: true })} label="Password" type="password" className='form_inputs'
                    error={errors.password ? true : false}
                    helperText={errors.login ? "Password is required" : ""} />

                <Button disabled={isSubmitting} className='form_button' color="success" variant="contained" type="submit">Log In</Button >
                <div className='form_other_login'>
                    {/* <Link href='#'>Forgot password?</Link> */}
                    <Link href={pageUrls.URL_REGISTER}>Register</Link>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;
