import { ServiceBase } from "../core/service-base";
import Result from "../core/result";
import { LocalStorageUserDataModel } from "../models/LocalStorageUserDataModel";
import { showErrors } from "../common/utils";
import WordModel from "../models/WordModel";
import { SearchWordsFilters, SearchWordsResultDto } from "../models/WordsPageSettingsModel";

export default class WordsService extends ServiceBase {
    public async getAllLanguages(): Promise<Result<Array<string>>> {
        const result = await this.requestJson<Array<string>>({
            url: `api/words/all-languages`,
            method: "GET",
        });
        return result;
    }

    public async getWordsByFilters(filters: SearchWordsFilters): Promise<Result<SearchWordsResultDto>> {
        console.log("getWordsByFilters", filters);
        let searchStr = filters.search?.trim()
            ? filters.search
                  .split(" ")
                  .filter((w) => w.length > 0)
                  .map((w) => w.trim())
                  .join(" ")
                  .trim()
            : null;

        const result = await this.requestJson<SearchWordsResultDto>({
            url: `api/words/get-words-by-filters`,
            method: "GET",
            data: {
                langIso: filters.langIso,
                tags: filters.tags?.join("$$$"),
                search: searchStr,
                page: filters.page,
                pageSize: filters.pageSize,
                orderBy: filters.orderBy,
                sortDirection: filters.sortDirection,
                isStrictTagsSearch: filters.isStrictTagsSearch,
            },
        });
        return result;
    }

    public async saveWord(
        langCode: string,
        value: string,
        translation: string,
        tags: Array<string>
    ): Promise<Result<LoginDataModel>> {
        const result = await this.requestJson<LoginDataModel>({
            url: `api/words/save-new-word`,
            method: "POST",
            data: {
                lang_iso: langCode,
                value: value,
                translation: translation,
                tags: tags,
            },
        });
        return result;
    }
}

export interface LoginDataModel {
    access_token: string;
    login: string;
}
