import * as React from "react";

import { Helmet } from "react-helmet";

const NotFoundPage: React.FC = () => {
    return (
        <section className="mainContent">
            <Helmet>
                <title>Страница не найдена</title>
            </Helmet>

            <div className="containerSmall">
                <p className="text-center" style={{ fontSize: "3rem" }}>
                    404 - Страница не найдена
                </p>
            </div>
        </section>
    );
};

export default NotFoundPage;
