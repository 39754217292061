import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/authUtils";
import { insertOrUpdateWordsPageSettings, useWordsPageSettings } from "../common/wordsPageSettingsCache";
import { pageUrls } from "../common/constants";
import Placeholder from "../components/Placeholder";
import WordsPageSettingsModel, {
    SearchWordsResultDto,
    WordsTableFilters,
    WordsTableParams,
} from "../models/WordsPageSettingsModel";
import WordsPageFilterPanel from "./wordsPage/WordsPageFilterPanel";
import TagsService from "../services/TagsService";
import MainPageSettingsModel from "../models/MainPageSettingsModel";
import SettingsService from "../services/SettingsService";
import WordsService from "../services/WordsService";
import WordModel from "../models/WordModel";
import ComponentInfo from "../components/ComponentInfo";
import WordsTable from "./wordsPage/WordsTable";

interface WordsPageState {
    isUpdating: boolean;
    isInitFetching: boolean;
    tableFilters: WordsTableFilters;
    tableParams: WordsTableParams;
    allTags: Array<string>;
    allLangs: Array<string>;
    words: Array<WordModel>;
}

const WordsPage: React.FC = () => {
    const { isAuthenticated } = useAuth();
    const defaultWordsPageSettings = useWordsPageSettings();

    const [pageState, setPageState] = useState<WordsPageState>({
        isUpdating: false,
        isInitFetching: true,
        tableFilters: defaultWordsPageSettings.tableFilters,
        tableParams: defaultWordsPageSettings.tableParams,
        allTags: [],
        allLangs: [],
        words: [],
    });

    console.log("[WordsPage] render", pageState);

    const handleRefetchWords = () => {
        if (
            pageState.tableFilters.langIso !== "" &&
            pageState.tableFilters.langIso !== undefined &&
            pageState.tableFilters.langIso !== null
        ) {
            setIsUpdating(true);
            Promise.all([findWordsByFiltres(pageState.tableFilters, pageState.tableParams)])
                .then(([searchResult]) => {
                    setPageState((prevState) => ({
                        ...prevState,
                        tableParams: {
                            ...prevState.tableParams,
                            total: searchResult.total,
                        },
                        words: searchResult.words,
                        isUpdating: false,
                    }));
                })
                .catch(() => {
                    setIsUpdating(false);
                });
        }
    };

    const findWordsByFiltres = async (
        filters: WordsTableFilters,
        params: WordsTableParams
    ): Promise<SearchWordsResultDto> => {
        let wordsService = new WordsService();
        let wordsResult = await wordsService.getWordsByFilters({
            langIso: filters.langIso,
            tags: filters.tags,
            search: filters.search,
            page: params.page,
            pageSize: params.pageSize,
            orderBy: params.orderBy,
            sortDirection: params.sortDirection,
            isStrictTagsSearch: filters.isStrictTagsSearch,
        });
        return wordsResult.value;
    };

    const setIsUpdating = (isUpdating: boolean) => {
        setPageState((prevState) => ({
            ...prevState,
            isUpdating: isUpdating,
        }));
    };

    const setIsFetching = (isFetching: boolean) => {
        setPageState((prevState) => ({
            ...prevState,
            isInitFetching: isFetching,
        }));
    };

    const setTableFilters = (newFilters: WordsTableFilters) => {
        if (pageState.tableFilters.langIso !== newFilters.langIso) {
            setIsUpdating(true);
            Promise.all([getAllTagsForLanguage(newFilters.langIso)])
                .then(([allTags]) => {
                    setPageState((prevState) => ({
                        ...prevState,
                        tableFilters: {
                            ...newFilters,
                            tags: [],
                        },
                        tableParams: {
                            ...prevState.tableParams,
                            page: 1,
                        },
                        allTags: allTags,
                        isUpdating: false,
                    }));
                })
                .catch(() => {
                    setIsUpdating(false);
                });
        } else {
            setPageState((prevState) => ({
                ...prevState,
                tableFilters: {
                    ...newFilters,
                },
                tableParams: {
                    ...prevState.tableParams,
                    page: 1,
                },
            }));
        }
    };

    const setTableParams = (params: WordsTableParams) => {
        console.log("[Config page] setTableParams", params);
        setPageState((prevState) => ({
            ...prevState,
            tableParams: params,
        }));
    };

    let navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            console.log("[Config page] init request", pageState);
            Promise.all([getAllUsersLanguages()]).then(([allLanguages]) => {
                if (allLanguages.length !== 0) {
                    Promise.all([getAllTagsForLanguage(allLanguages[0])]).then(([allTags]) => {
                        setPageState((prevState) => ({
                            ...prevState,
                            tableFilters: {
                                ...prevState.tableFilters,
                                langIso: allLanguages[0],
                            },
                            allTags: allTags,
                            allLangs: allLanguages,
                            isInitFetching: false,
                        }));
                    });
                } else {
                    setIsFetching(false);
                }
            });
        } else {
            navigate(pageUrls.URL_LOGIN);
        }
    }, []);

    useEffect(() => {
        console.log("[WordsPage] useEffect for change tableFilters and refetch words");
        handleRefetchWords();
        insertOrUpdateWordsPageSettings({
            tableFilters: pageState.tableFilters,
            tableParams: pageState.tableParams,
        });
    }, [
        pageState.tableFilters.langIso,
        pageState.tableFilters.search,
        pageState.tableFilters.tags,
        pageState.tableFilters.isStrictTagsSearch,
        pageState.tableParams.page,
        pageState.tableParams.pageSize,
        pageState.tableParams.orderBy,
        pageState.tableParams.sortDirection,
    ]);

    // Function to get all tags for a given language
    const getAllTagsForLanguage = async (languageIsoCode: string): Promise<string[]> => {
        let tagsService = new TagsService();
        let tagsResult = await tagsService.GetAllTags(languageIsoCode);
        return tagsResult.value;
    };

    // Function to get all languages for the current user (created words by user)
    const getAllUsersLanguages = async (): Promise<Array<string>> => {
        let wordsService = new WordsService();
        let languagesResult = await wordsService.getAllLanguages();
        return languagesResult.value;
    };

    const content = pageState.isInitFetching ? (
        <Placeholder />
    ) : pageState.allLangs.length === 0 ? (
        <div className="words-page">
            <div className="center-container">
                <div className="spider-web">
                    {/* Spider (Pure CSS) */}
                    <div className="spider"></div>
                </div>

                {/* Вертикальная красная линия с анимацией */}
                <div className="vertical-line"></div>

                {/* Alert box */}
                <div className="alert" role="alert">
                    You have no words yet. Please add some words first.
                </div>
            </div>
        </div>
    ) : (
        <WordsPageContent
            allLangs={pageState.allLangs}
            isUpdating={pageState.isUpdating}
            setIsUpdating={setIsUpdating}
            allTags={pageState.allTags}
            filters={pageState.tableFilters}
            handleFilterChange={setTableFilters}
            words={pageState.words}
            tableParams={pageState.tableParams}
            setTableParams={setTableParams}
        />
    );
    return (
        <>
            {/* {pageState.isUpdating ? <Placeholder isUpdating={pageState.isUpdating} /> : null} */}
            {content}
        </>
    );
};

interface WordsPageContentProps {
    setIsUpdating: (isUpdating: boolean) => void;
    isUpdating: boolean;
    allTags: string[];
    allLangs: string[];
    filters: WordsTableFilters;
    words: Array<WordModel>;
    tableParams: WordsTableParams;
    setTableParams: (params: WordsTableParams) => void;
    handleFilterChange: (filters: WordsTableFilters) => void;
}

const WordsPageContent: React.FC<WordsPageContentProps> = (props) => {
    return (
        <>
            <WordsPageFilterPanel
                allLangs={props.allLangs}
                allTags={props.allTags}
                initialFilters={props.filters}
                onFilterChange={props.handleFilterChange}
            />
            <WordsTable
                searchStr={props.filters.search}
                tableParams={props.tableParams}
                setTableParams={props.setTableParams}
                words={props.words}
            />
        </>
    );
};

export default WordsPage;
