import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../common/constants";
import { useAuth } from "../common/authUtils";
import UserMenuComponent from "./navMenuComponents/UserMenuComponent";
import Button from "@mui/material/Button";
interface MenuItem {
    title: string;
    url: string;
}
const pagesMenuItems: Array<MenuItem> = [
    { title: "Words", url: pageUrls.URL_WORDS },
    { title: "Config", url: pageUrls.URL_CONFIG },
    // { title: 'Logout', url: pageUrls.URL_LOGIN }
];

const NavMenu_1 = () => {
    const { userLogin } = useAuth();

    let navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    return (
        <AppBar position="static">
            <Container>
                <Toolbar disableGutters>
                    {/* custom Typography component orange gradient color with beautiful font stile like logo  */}
                    <Box sx={{ alignContent: "left", width: "30px", display: { xs: "block", sm: "none" } }}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    <Typography
                        variant="h6"
                        component="div"
                        onClick={() => navigate(pageUrls.URL_BASE)}
                        sx={{
                            flexGrow: 1,
                            alignContent: "left",
                            display: { xs: "block", sm: "block" },
                            cursor: "pointer",
                        }}
                    >
                        MyWords
                    </Typography>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        {pagesMenuItems.map((page) => (
                            <Button
                                key={page.title}
                                sx={{ color: "#fff" }}
                                onClick={() => {
                                    navigate(page.url);
                                }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>
                    {/* put box on the right side */}
                    <Box sx={{ display: { xs: "block", sm: "block" } }}>
                        <UserMenuComponent />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavMenu_1;
