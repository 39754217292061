import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { pageUrls } from "../../common/constants";
import { logout, useAuth } from "../../common/authUtils";
import { useNavigate } from "react-router-dom";

export default function UserMenuComponent() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { userLogin } = useAuth();
    let navigate = useNavigate();

    return (
        <div>
            <Button
                color="inherit"
                id="fade-button"
                sx={{ color: "#d17425", fontWeight: "bolder", fontSize: "18px" }}
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                {userLogin}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>Settings</MenuItem> */}
                <MenuItem
                    onClick={() => {
                        logout();
                        navigate(pageUrls.URL_LOGIN);
                        handleClose();
                    }}
                >
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}
