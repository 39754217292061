import * as React from "react";
import { Helmet } from "react-helmet";

const ForbiddenPage: React.FC = () => {
    return (
        <section className="mainContent">
            <Helmet>
                <title>Доступ запрещён</title>
            </Helmet>

            <br />

            <div className="containerSmall">
                <p className="text-center" style={{ fontSize: "3rem" }}>
                    Доступ запрещён
                </p>
            </div>
        </section>
    );
};

export default ForbiddenPage;
