// import "bootstrap/dist/css/bootstrap.css";
import '@fontsource/roboto/400.css';
import "./styles/styles.scss";

import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// After
import { createRoot } from 'react-dom/client';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <BrowserRouter basename={baseUrl!}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </BrowserRouter>
);