import * as React from "react";
// import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { isDef, showErrors, showSuccess } from "../../common/utils";
import WordModel from "../../models/WordModel";
import { CustomSelect, OptionType } from "../../components/CustomSelect";
import ComponentInfo from "../../components/ComponentInfo";
import MainPageSettingsModel from "../../models/MainPageSettingsModel";
import { languagesIsoCodes } from "../../common/constants";
import { useNavigate } from "react-router-dom";
// import WordService from "../services/WordService";
import { useForm, SubmitHandler, set } from "react-hook-form";
import { Link, TextField, Button } from "@mui/material";
import WordsService from "../../services/WordsService";
import TagsService from "../../services/TagsService";
import SettingsService from "../../services/SettingsService";
import { useEffect, useState } from "react";

type FormInputs = {
    langCode: string;
    value: string;
    translation: string;
    tags: Array<string>;
};

type Props = {
    mainPageSettings: MainPageSettingsModel | null;
    isUpdating: boolean;
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
    getMainPageSettings: () => void;
};
const SaveNewWordBlock: React.FC<Props> = (props) => {
    const [isSubmitting, setSubmitting] = React.useState<boolean>(false); // когда внутренний запрос идёт
    const [isTagsFetching, setIsTagsFetching] = React.useState<boolean>(false); // когда внутренний запрос идёт

    let tagsService = new TagsService();
    let settingsService = new SettingsService();

    const [allTags, setAllTags] = useState<Array<string>>([]);
    const getAllTags = async (lang_iso: string) => {
        let tagssResult = await tagsService.GetAllTags(lang_iso);

        if (!tagssResult.hasErrors) {
            setAllTags(tagssResult.value);
        } else {
            showErrors(...tagssResult.errors);
        }
    };

    const updateMainPageSettings = async (lang_iso: string, tags: Array<string>) => {
        let settingsResult = await settingsService.updateMainPageSettings(lang_iso, tags);

        if (settingsResult.hasErrors) {
            showErrors(...settingsResult.errors);
        }
    };

    let initRequest = false;

    useEffect(() => {
        if (!initRequest) {
            initRequest = true;
            setIsTagsFetching(true);
            if (props.mainPageSettings?.lang_iso) {
                getAllTags(props.mainPageSettings?.lang_iso).then(() => {
                    setIsTagsFetching(false);
                });
            }
        }
    }, [props.mainPageSettings?.lang_iso]);

    const {
        reset,
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm<FormInputs>({
        defaultValues: {
            langCode: props.mainPageSettings?.lang_iso ?? "en",
            value: "",
            translation: "",
            tags: props.mainPageSettings?.tags ?? [],
        },
    });
    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        props.setIsUpdating(true);
        const service = new WordsService();
        let result = await service.saveWord(data.langCode, data.value, data.translation, data.tags);
        if (!result.hasErrors) {
            showSuccess("Word was saved successfully");

            reset({
                value: "",
                translation: "",
                tags: [...data.tags],
                langCode: data.langCode,
            });

            let allTagsRes = [...allTags, ...data.tags];
            // distinct allTagsRes values and set in allTagsState
            const distinctTags = allTagsRes.filter((v, i, a) => a.indexOf(v) === i);
            setAllTags(distinctTags);
        }

        console.log("Submitting new word", data);
        props.setIsUpdating(false);
    };

    let allLangsOptions: Array<OptionType> = [];
    for (const [key, value] of Object.entries(languagesIsoCodes)) {
        allLangsOptions.push({ value: key, label: value });
    }
    allLangsOptions = allLangsOptions.sort((x, y) => {
        return x.label < y.label ? 1 : -1;
    });

    let selectedDefaultTags = isDef(getValues("tags"))
        ? getValues("tags").map((el) => {
              return {
                  value: el,
                  label: el,
              } as OptionType;
          })
        : [];

    const allTagsOptions = allTags
        .map((tag) => {
            return {
                value: tag,
                label: tag,
            } as OptionType;
        })
        .sort((x, y) => {
            return x.label < y.label ? 1 : -1;
        });

    return (
        <div className="save-new-word-container">
            <div className="main-page-form-container">
                <div className="title">Add new word</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form_items">
                    <CustomSelect
                        fieldName={"langCode"}
                        setValue={setValue}
                        {...register("langCode", { required: true })}
                        placeholder="Select a language"
                        options={allLangsOptions}
                        isCreatable={false}
                        isMulti={false}
                        selectedValues={[
                            {
                                value: getValues("langCode"),
                                // @ts-ignore
                                label: languagesIsoCodes[getValues("langCode") ?? "en"],
                            },
                        ]}
                        onChangeCallback={() => {
                            setValue("tags", []);
                            updateMainPageSettings(getValues("langCode"), []);
                            props.getMainPageSettings();
                        }}
                    />

                    <TextField
                        multiline
                        {...register("value", { required: true })}
                        label="Word or phrase"
                        type="text"
                        className="text-field margin-top-20"
                        error={errors.value ? true : false}
                        helperText={errors.value ? "Word is required" : ""}
                    />

                    <TextField
                        multiline
                        {...register("translation", { required: true })}
                        label="Translation"
                        type="text"
                        className="text-field margin-top-20"
                        error={errors.translation ? true : false}
                        helperText={errors.translation ? "Translation is required" : ""}
                    />

                    <CustomSelect
                        fieldName={"tags"}
                        setValue={setValue}
                        {...register("tags", { required: false })}
                        placeholder="Select a tags"
                        options={allTagsOptions}
                        isCreatable={true}
                        isMulti={true}
                        selectedValues={selectedDefaultTags}
                        onChangeCallback={() => {
                            updateMainPageSettings(getValues("langCode"), getValues("tags"));
                        }}
                    />

                    <Button
                        disabled={props.isUpdating}
                        className="button"
                        color="success"
                        variant="contained"
                        type="submit"
                    >
                        Add
                    </Button>

                    {/* <ComponentInfo props={props} form={
                        {
                            langCode: watch("langCode"),
                            value: watch("value"),
                            translation: watch("translation"),
                            tags: watch("tags")
                        } as FormInputs
                    } /> */}
                </form>
            </div>
        </div>
    );
};

export default SaveNewWordBlock;
