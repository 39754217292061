import { localStorageConstants } from "./constants";
import { LocalStorageUserDataModel } from "../models/LocalStorageUserDataModel";
import { AuthCommonModel } from "../models/AuthCommonModel";

export const useAuth = (): AuthCommonModel => {
    const localStorageData = localStorage.getItem(
        localStorageConstants.USER_DATA_STORAGE_NAME
    );
    if (localStorageData) {
        const localUserData: LocalStorageUserDataModel =
            JSON.parse(localStorageData);
        return {
            accessToken: localUserData.accessToken,
            isAuthenticated: !!localUserData.accessToken,
            userLogin: localUserData.userLogin,
        } as AuthCommonModel;
    } else {
        return {
            accessToken: null,
            isAuthenticated: false,
            userLogin: null
        } as AuthCommonModel;
    }
};

export const login = (
    accessToken: string | null,
    userLogin: string | null
) => {
    localStorage.setItem(
        localStorageConstants.USER_DATA_STORAGE_NAME,
        JSON.stringify({
            accessToken: accessToken,
            userLogin: userLogin
        } as LocalStorageUserDataModel)
    );

    localStorage.setItem(
        "from_auth",
        "true"
    );
};

export const logout = () => {
    localStorage.removeItem(localStorageConstants.USER_DATA_STORAGE_NAME);
};
