import { ServiceBase } from "../core/service-base";
import Result from "../core/result";

export default class TagsService extends ServiceBase {
    public async GetAllTags(langCode: string): Promise<Result<Array<string>>> {
        const result = await this.requestJson<Array<string>>({
            url: `api/tags/get-tags`,
            method: "GET",
            data: {
                lang_iso: langCode,
            },
        });
        return result;
    }
}
