export const pageUrls = {
    URL_BASE: "/",
    URL_LOGIN: "/login",
    URL_REGISTER: "/register",
    URL_CONFIG: "/config",
    URL_WORDS: "/words",

    URL_NOT_FOUND: "/not-found",
    URL_FORBIDDEN: "/forbidden",
};

export const localStorageConstants = {
    USER_DATA_STORAGE_NAME: "userData",
    WORDS_PAGE_DATA_STORAGE_NAME: "wordsPageDataData",
};

export const languagesIsoCodes = {
    aa: "(aa) Afar",
    ab: "(ab) Abkhazian",
    ae: "(ae) Avestan",
    af: "(af) Afrikaans",
    ak: "(ak) Akan",
    am: "(am) Amharic",
    an: "(an) Aragonese",
    ar: "(ar) Arabic",
    as: "(as) Assamese",
    av: "(av) Avaric",
    ay: "(ay) Aymara",
    az: "(az) Azerbaijani",
    ba: "(ba) Bashkir",
    be: "(be) Belarusian",
    bg: "(bg) Bulgarian",
    bh: "(bh) Bihari languages",
    bm: "(bm) Bambara",
    bi: "(bi) Bislama",
    bn: "(bn) Bengali",
    bo: "(bo) Tibetan",
    br: "(br) Breton",
    bs: "(bs) Bosnian",
    ca: "(ca) Catalan; Valencian",
    ce: "(ce) Chechen",
    ch: "(ch) Chamorro",
    co: "(co) Corsican",
    cr: "(cr) Cree",
    cs: "(cs) Czech",
    cu: "(cu) Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    cv: "(cv) Chuvash",
    cy: "(cy) Welsh",
    da: "(da) Danish",
    de: "(de) German",
    dv: "(dv) Divehi; Dhivehi; Maldivian",
    dz: "(dz) Dzongkha",
    ee: "(ee) Ewe",
    el: "(el) Greek, Modern (1453-)",
    en: "(en) English",
    eo: "(eo) Esperanto",
    es: "(es) Spanish; Castilian",
    et: "(et) Estonian",
    eu: "(eu) Basque",
    fa: "(fa) Persian",
    ff: "(ff) Fulah",
    fi: "(fi) Finnish",
    fj: "(fj) Fijian",
    fo: "(fo) Faroese",
    fr: "(fr) French",
    fy: "(fy) Western Frisian",
    ga: "(ga) Irish",
    gd: "(gd) Gaelic; Scottish Gaelic",
    gl: "(gl) Galician",
    gn: "(gn) Guarani",
    gu: "(gu) Gujarati",
    gv: "(gv) Manx",
    ha: "(ha) Hausa",
    he: "(he) Hebrew",
    hi: "(hi) Hindi",
    ho: "(ho) Hiri Motu",
    hr: "(hr) Croatian",
    ht: "(ht) Haitian; Haitian Creole",
    hu: "(hu) Hungarian",
    hy: "(hy) Armenian",
    hz: "(hz) Herero",
    ia: "(ia) Interlingua (International Auxiliary Language Association)",
    id: "(id) Indonesian",
    ie: "(ie) Interlingue; Occidental",
    ig: "(ig) Igbo",
    ii: "(ii) Sichuan Yi; Nuosu",
    ik: "(ik) Inupiaq",
    io: "(io) Ido",
    is: "(is) Icelandic",
    it: "(it) Italian",
    iu: "(iu) Inuktitut",
    ja: "(ja) Japanese",
    jv: "(jv) Javanese",
    ka: "(ka) Georgian",
    kg: "(kg) Kongo",
    ki: "(ki) Kikuyu; Gikuyu",
    kj: "(kj) Kuanyama; Kwanyama",
    kk: "(kk) Kazakh",
    kl: "(kl) Kalaallisut; Greenlandic",
    km: "(km) Central Khmer",
    kn: "(kn) Kannada",
    ko: "(ko) Korean",
    kr: "(kr) Kanuri",
    ks: "(ks) Kashmiri",
    ku: "(ku) Kurdish",
    kv: "(kv) Komi",
    kw: "(kw) Cornish",
    ky: "(ky) Kirghiz; Kyrgyz",
    la: "(la) Latin",
    lb: "(lb) Luxembourgish; Letzeburgesch",
    lg: "(lg) Ganda",
    li: "(li) Limburgan; Limburger; Limburgish",
    ln: "(ln) Lingala",
    lo: "(lo) Lao",
    lt: "(lt) Lithuanian",
    lu: "(lu) Luba-Katanga",
    lv: "(lv) Latvian",
    mg: "(mg) Malagasy",
    mh: "(mh) Marshallese",
    mi: "(mi) Maori",
    mk: "(mk) Macedonian",
    ml: "(ml) Malayalam",
    mn: "(mn) Mongolian",
    mr: "(mr) Marathi",
    ms: "(ms) Malay",
    mt: "(mt) Maltese",
    my: "(my) Burmese",
    na: "(na) Nauru",
    nb: "(nb) Bokmål, Norwegian; Norwegian Bokmål",
    nd: "(nd) Ndebele, North; North Ndebele",
    ne: "(ne) Nepali",
    ng: "(ng) Ndonga",
    nl: "(nl) Dutch; Flemish",
    nn: "(nn) Norwegian Nynorsk; Nynorsk, Norwegian",
    no: "(no) Norwegian",
    nr: "(nr) Ndebele, South; South Ndebele",
    nv: "(nv) Navajo; Navaho",
    ny: "(ny) Chichewa; Chewa; Nyanja",
    oc: "(oc) Occitan (post 1500)",
    oj: "(oj) Ojibwa",
    om: "(om) Oromo",
    or: "(or) Oriya",
    os: "(os) Ossetian; Ossetic",
    pa: "(pa) Panjabi; Punjabi",
    pi: "(pi) Pali",
    pl: "(pl) Polish",
    ps: "(ps) Pushto; Pashto",
    pt: "(pt) Portuguese",
    qu: "(qu) Quechua",
    rm: "(rm) Romansh",
    rn: "(rn) Rundi",
    ro: "(ro) Romanian; Moldavian; Moldovan",
    ru: "(ru) Russian",
    rw: "(rw) Kinyarwanda",
    sa: "(sa) Sanskrit",
    sc: "(sc) Sardinian",
    sd: "(sd) Sindhi",
    se: "(se) Northern Sami",
    sg: "(sg) Sango",
    si: "(si) Sinhala; Sinhalese",
    sk: "(sk) Slovak",
    sl: "(sl) Slovenian",
    sm: "(sm) Samoan",
    sn: "(sn) Shona",
    so: "(so) Somali",
    sq: "(sq) Albanian",
    sr: "(sr) Serbian",
    ss: "(ss) Swati",
    st: "(st) Sotho, Southern",
    su: "(su) Sundanese",
    sv: "(sv) Swedish",
    sw: "(sw) Swahili",
    ta: "(ta) Tamil",
    te: "(te) Telugu",
    tg: "(tg) Tajik",
    th: "(th) Thai",
    ti: "(ti) Tigrinya",
    tk: "(tk) Turkmen",
    tl: "(tl) Tagalog",
    tn: "(tn) Tswana",
    to: "(to) Tonga (Tonga Islands)",
    tr: "(tr) Turkish",
    ts: "(ts) Tsonga",
    tt: "(tt) Tatar",
    tw: "(tw) Twi",
    ty: "(ty) Tahitian",
    ug: "(ug) Uighur; Uyghur",
    uk: "(uk) Ukrainian",
    ur: "(ur) Urdu",
    uz: "(uz) Uzbek",
    ve: "(ve) Venda",
    vi: "(vi) Vietnamese",
    vo: "(vo) Volapük",
    wa: "(wa) Walloon",
    wo: "(wo) Wolof",
    xh: "(xh) Xhosa",
    yi: "(yi) Yiddish",
    yo: "(yo) Yoruba",
    za: "(za) Zhuang; Chuang",
    zh: "(zh) Chinese",
    zu: "(zu) Zulu",
};
