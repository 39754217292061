import React from "react";
import Select, { Options } from "react-select";

import Creatable from "react-select/creatable";
import { v4 as uuid } from "uuid";
import makeAnimated from "react-select/animated";
import { UseFormSetValue } from "react-hook-form";
import "./CustomSelect.css";

export interface OptionType {
    label: string;
    value: string | number;
}

type OptionsType = Array<OptionType>;

interface CustomSelectProps {
    setValue: any;
    fieldName: string;
    options: OptionsType;
    isMulti?: boolean;
    isCreatable: boolean;
    selectedValues: OptionsType;
    placeholder?: string;
    onChangeCallback?: () => void;
}

export const CustomSelect = ({
    setValue,
    fieldName,
    options,
    isMulti = false,
    isCreatable,
    selectedValues,
    placeholder,
    onChangeCallback,
}: CustomSelectProps) => {
    const onChange = (option: any) => {
        if (option) {
            const isOptionIsArray = Array.isArray(option);
            const value = isOptionIsArray
                ? option.map((item: any) => {
                      return item.value;
                  })
                : option.value;
            setValue(fieldName, value);
        } else {
            setValue(fieldName, []);
        }

        if (onChangeCallback) {
            onChangeCallback();
        }
    };

    return (
        <>
            {isCreatable ? (
                // @ts-ignore
                <Creatable
                    className="Select-menu-outer"
                    placeholder={placeholder}
                    components={makeAnimated()}
                    name={fieldName}
                    defaultValue={selectedValues}
                    onChange={onChange}
                    createOptionPosition={"last"}
                    isMulti={isMulti}
                    options={options}
                    classNamePrefix="select"
                />
            ) : (
                <Select
                    className="Select-menu-outer"
                    components={makeAnimated()}
                    name={fieldName}
                    defaultValue={selectedValues}
                    onChange={onChange}
                    isMulti={isMulti}
                    options={options}
                    styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                />
            )}
        </>
    );
};
